/* eslint-disable */
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    // component: HomeView,
    component: () => import("../views/HomeView.vue"),
    // component: () => import("../views/HomeView12.vue"),
  },
  {
    path: "/management",
    name: "management",
    component: () => import("../views/ManagementView.vue"),
  },
  {
    path: "/kpi",
    name: "kpi",
    component: () => import("../views/KpiView.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/admin",
    name: "admin",
    component: () => import("../views/AdminView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  //인증 없이 접근 가능한 경로(화면)
  const publicPages = ["/login"];

  //인증이 없어도 접근 가능한 경로
  const authRequired = !publicPages.includes(to.path);

  //웹토큰을 가져와서 확인
  //웹토큰 있으면 : 회원
  //웹토큰 없으면 : 비회원
  const loggedIn = localStorage.getItem("user");

  //비회원일 경우
  if (authRequired && !loggedIn) {
    //강제로 로그인 화면으로 전환
    alert("로그인이 필요한 서비스입니다.");
    next("/login");
  } else {
    //회원일경우
    //다음화면으로 이동
    next();
  }
});

export default router;
