//AuthService
//login / logout / register 처리
import serverAPI from '../api/serverAPI'


class AuthService {
    //로그인 메서드
    login(user) {
        //springboot 서버로 로그인정보(이름, 암호) 확인 요청
        return serverAPI.post("/auth/signin", {
            username: user.username,
            password: user.password
        })
        //성공하면 then으로 결과가 들어옴
            .then(response => {
                //로컬 스토리지에 user라는 이름으로 저장
                if(response.data.token) {
                    localStorage.setItem("user",JSON.stringify(response.data))
                }
                return response.data;
            })
    }
    //JWT(Java Web Token) 로컬 저장소(localStorage)에서 제거
    logout() {
        localStorage.removeItem("user");
    }
    //회원가입
    register(user) {
        return serverAPI.post("/auth/signup", {
            username: user.username,
            compName    : user.compName,
            certKey   : user.certKey,
            hourlyOutput   : user.hourlyOutput,
            processDefect   : user.processDefect,
            role    : user.role,
        })
    }

  // PUT 방식 : springBoot 서버에 회원 수정을 요청(update)
  update (user) {
    return serverAPI.put(`/auth/user-update`, {
      username: user.username,
      compName    : user.compName,
      certKey   : user.certKey,
      role    : user.role,
    })
  }




    // PUT 방식 : springBoot 서버에 회원 탈퇴를 요청(update)
    delete (id) {
        return serverAPI.put(`/auth/user-deletion/${ id }`)
      }
  
    
      // 패스워드 찾기(변경) 요청
      findPassword (user) {
        return serverAPI.put(`/auth/getPassword/`, {
          username   : user.username,
          newPassword: user.newPassword,
        })
      }


      socialLogin(provider) {
        const frontendUrl = window.location.protocol + "//" + window.location.host ;
        window.location.href = serverAPI.post('/auth/authorize/') + provider + "?redirect_url=" + frontendUrl;
      }
    

}


export default new AuthService();