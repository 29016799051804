<template>
  <div id="app" :style="cssVariable">
    <router-view class="view-wrap" />
  </div>
</template>

<script>
/* eslint-disable */
import Stomp from 'webstomp-client'
import SockJS from 'sockjs-client'
import { mapState } from 'vuex';
import { EventBus } from '@/eventBus.js';

export default {
  name: 'App',
  data() {
    return {
      stomp: null,
      resMsg: '메시지 아직 안 옴',
      roomId: null,
    }
  },
  computed: {
    cssVariable() {
      return {
        '--main-color': '#3c1e1d',
        '--sub-color': '#7e6767',
        '--shadow-style': '3px 3px 4px 0px rgba(0,0,0,0.4)',
        '--btn-style': '1px 1px 2px 0px rgba(0,0,0,0.3)',
      }
    },
    ...mapState({
      common: state => state.common
    }),
    currentUser() {
        return this.$store.state.auth.user;
    },
  },
  created() {
    EventBus.$on('disconnect', this.disconnect);
    EventBus.$on('connect', this.connect);
  },
  mounted() {
    const vm = this;
    const interval = setInterval(function () {
      // console.log(vm.$store.state.auth.user);
      if (vm.$store.state.auth.user !== null) {
        vm.connect();
        clearInterval(interval);
      }
    }, 1000)
  },
  methods: {
    // 웹소켓 방 생성
    connect() {
      // 웹소켓 이미 연결되어 있으면 함수 실행 x
      if (this.roomId === this.currentUser.certKey) return;
      const vm = this;
      const serverURL = `${this.common.ip}:9000/stomp/chat`
      let socket = new SockJS(serverURL);
      this.stomp = Stomp.over(socket, {debug: false});
      console.log(`소켓 연결을 시도합니다. 서버 주소: ${serverURL}`)
      
      this.stomp.connect({}, function () {
        console.log("STOMP Connection")
        vm.roomId = vm.currentUser.certKey;
        vm.stomp.subscribe(`/sub/chat/room/${vm.roomId}`, function (res) {
          // console.log('구독으로 받은 메시지 입니다.', res.body);
          const body = JSON.parse(res.body)
          // console.log(body);
          const data = JSON.parse(body.message);
          console.log(data);
          vm.$store.commit('common/updateData', data);
        });
        vm.stomp.send('/pub/chat/enter', JSON.stringify({roomId: vm.roomId}))
        setInterval(() => {
          vm.stomp.send('/pub/chat/enter', JSON.stringify({roomId: vm.roomId}))
        }, 1000)
      });
    },
    // 로그아웃시 웹소켓 연결 끊기
    disconnect() {
      const vm = this;
      if (this.stomp.connected) {
        vm.stomp.disconnect();
        console.log('STOMP connection disconnect');
        vm.roomId = null;
      }
    }
  }
}
</script>

<style>
@font-face {
    font-family: 'ONE-Mobile-Regular';
    src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2105_2@1.0/ONE-Mobile-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

* {
  margin: 0; 
  padding: 0; 
  line-height: 1; 
  box-sizing: border-box; 
  list-style: none;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
h1 {font-size: 105px; color: var(--main-color); font-family: 'ONE-Mobile-Regular'; font-weight: bold; text-align: center; color: #fff;}
h2, h3, p, button {font-size: 54px; color: var(--main-color); font-family: 'ONE-Mobile-Regular';}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
#app::-webkit-scrollbar {
  width: 0px;  /* Remove scrollbar space */
  height: 0px;
  background: transparent;  /* Optional: just make scrollbar invisible */
}
/* .view-wrap {
  background-color: #ccc;
} */
</style>
