export const common = {
  namespaced: true,
  state: {
    ip: "https://gymes.kr",
    totalNum: 0, // 양품총량
    totalProduct: 0, // 총 생산량
    product: 0, // 현재 양품
    totalDefective: 0, // 총 불량품
    defective: 0, // 불량률
    selectedCode: {
      id: null,
      certKey: null,
      compId: null,
      insertTime: null,
      prodCode: null,
      prodName: null,
      hourlyOutput: null,
      processDefect: null,
    }, // 선택 제품
    productCodes: [], // 제품 목록
    socketProdCode: "", // 웹소켓으로 받은 제품 코드
    machineNum: 1, // 기기 번호
  },
  mutations: {
    changeSelectedCode(state, payload) {
      state.selectedCode = payload;
    },
    updateData(state, newValue) {
      // console.log("mutations", newValue.totalProduct);
      state.totalNum = newValue.totalNum;
      state.totalProduct = newValue.totalProduct;
      state.product = newValue.product;
      state.totalDefective = newValue.totalDefective;
      state.defective = newValue.defective;
      state.socketProdCode = newValue.prodCode;
    },
  },
};
